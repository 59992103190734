import { classNames } from "@/utilities";
import { candidateUnlockThreshold } from "@/constants";
import { useGetOrganizationDataQuery } from "@/services/modules/organization";

const CreditsRemaining = ({ user }) => {
    const { data } = useGetOrganizationDataQuery(
        { id: user?.organization?.id },
        { skip: !user }
    );
    let credits_remaining = 0;
    if (data && data?.organization) {
        credits_remaining = data?.organization?.credits_remaining;
    }

    let bgColor = "bg-primary-50",
        textColor = "text-primary-800",
        text = "Credits";

    if (credits_remaining <= candidateUnlockThreshold.min) {
        bgColor = "bg-red-100";
        textColor = "text-red-800";
    } else if (credits_remaining < candidateUnlockThreshold.max) {
        bgColor = "bg-yellow-100";
        textColor = "text-yellow-800";
    }

    if (credits_remaining === 1) {
        text = "Credit";
    }

    return (
        <span
            className={classNames(
                "hidden lg:inline-flex items-center rounded-full px-3 py-0.5 text-sm font-bold",
                bgColor,
                textColor
            )}
        >
            {`${credits_remaining} ${text}`} Remaining
        </span>
    );
};

export default CreditsRemaining;
