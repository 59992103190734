import { api } from "@/services/api";
import {
    GET_ORGANIZATION,
    GET_ALL_COUNTRIES,
    GET_ALL_SKILLS,
    GET_PROFILE_DATA,
    GET_PUBLIC_ORGANIZATION,
    GET_CLIENT_NOTIFICATION_TYPES,
    UPDATE_USER,
    GET_CURRENT_USER,
} from "@/gql/constants";

export const organizationApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOrganizationData: build.query({
            query: (variables) => ({ document: GET_ORGANIZATION, variables }),
        }),
        getAllCountries: build.query({
            query: () => ({ document: GET_ALL_COUNTRIES }),
        }),
        getClientNotificationTypes: build.query({
            query: () => ({ document: GET_CLIENT_NOTIFICATION_TYPES }),
        }),
        getAllSkills: build.query({
            query: (variables) => ({ document: GET_ALL_SKILLS, variables }),
        }),
        getPublicOrganization: build.query({
            query: (variables) => ({
                document: GET_PUBLIC_ORGANIZATION,
                variables,
            }),
        }),
        getProfileData: build.query({
            query: () => ({ document: GET_PROFILE_DATA }),
        }),
        getCurrentUser: build.query({
            query: () => ({
                document: GET_CURRENT_USER,
            }),
            providesTags: ["getCurrentUser"],
        }),
        updateUser: build.mutation({
            query: (variables) => ({
                document: UPDATE_USER,
                variables,
            }),
            invalidatesTags: ["getCurrentUser"],
        }),
    }),
});

export const {
    useGetOrganizationDataQuery,
    useGetAllCountriesQuery,
    useGetAllSkillsQuery,
    useGetProfileDataQuery,
    useGetPublicOrganizationQuery,
    useGetClientNotificationTypesQuery,
    useUpdateUserMutation,
    useGetCurrentUserQuery,
} = organizationApi;
