import { getStaticColor, userRole } from "@/utilities";
import ProfilePicture from "@/components/candidates/ProfilePicture";
import { useGetOrganizationDataQuery } from "@/services/modules/organization";

const AccountMenuLogo = ({ user }) => {
    const { userIsCompany } = userRole(user);
    const { data } = useGetOrganizationDataQuery(
        { id: user?.organization?.id },
        { skip: !user }
    );
    let organization = data?.organization;

    if (!organization) {
        organization = user.organization;
    }

    return userIsCompany && organization ? (
        organization.logo ? (
            <div className="flex-shrink-0">
                <div
                    className="h-12 w-12 rounded-full bg-center bg-contain bg-no-repeat border border-primary-50"
                    style={{
                        backgroundImage: `url(${organization.logo})`,
                    }}
                    title={organization.name}
                />
            </div>
        ) : (
            <div
                className={`flex-shrink-0 h-12 w-12 rounded-full group-hover:opacity-75 font-bold flex items-center justify-center text-white ${
                    organization.name
                        ? getStaticColor(organization?.name)
                        : null
                }`}
            >
                {organization?.name.match(/\b\w/g)?.join("") ?? "?"}
            </div>
        )
    ) : (
        <ProfilePicture user={user} size={12} />
    );
};

export default AccountMenuLogo;
