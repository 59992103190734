import { getStaticColor } from "@/utilities";
import Image from "next/image";
import React from "react";

const ProfilePicture = ({ user, size, fontSize }) => {
    const { gravatar, pii, profile_picture } = user;
    let { name } = user;
    if (!name) {
        name = pii.name;
    }

    let height, width;

    fontSize = fontSize ? `text-${fontSize}` : "text-lg";

    switch (size.toString()) {
        case "8":
            height = "28px";
            width = "28px";
            break;

        case "10":
            height = "35px";
            width = "35px";
            break;

        case "12":
            height = "48px";
            width = "48px";
            break;

        case "14":
            height = "56px";
            width = "56px";
            break;

        case "16":
        default:
            height = "64px";
            width = "64px";
            break;
    }

    return gravatar || profile_picture ? (
        <Image
            className={` flex-shrink-0 rounded-full group-hover:opacity-75 font-bold flex items-center justify-center`}
            src={profile_picture ?? gravatar}
            alt={`${name}'s Profile Picture`}
            width={width}
            height={height}
        />
    ) : (
        <div
            className={` flex-shrink-0 rounded-full group-hover:opacity-75 font-bold flex items-center justify-center text-white ${fontSize} ${getStaticColor(
                name
            )}`}
            style={{
                height,
                width,
            }}
        >
            {name.match(/\b\w/g)?.join("").substring(0, 2) ?? "?"}
        </div>
    );
};

export default React.memo(ProfilePicture);
